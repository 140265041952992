<template>
  <div class="lightControl">
    <div class="header">
      <div class="header-back" @click="goback()">
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        />
      </div>
      <span>灯光控制</span>
      <div v-if="powerOn" class="light-sensitive">
        <div v-if="lights.lightSense === 1">
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/night.png"
            alt=""
          />
          <span>晚上</span>
        </div>
        <div v-else>
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/daytime.png"
          />
          <span>白天</span>
        </div>
      </div>
    </div>
    <template v-if="lights.vin">
      <div v-if="standby" class="warning">
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/warning.png"
        />
        <span>当前处于待机模式，灯光控制无法操作</span>
      </div>
      <div class="common light-card" :class="{ disabled: standby }">
        <div class="light-card-title">常用</div>
        <div class="light-card-content">
          <div class="light-item">
            <div :class="{ active: commonOFF }" @click="lightClick('commonOFF')">
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/off-${
                  commonOFF ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>关闭</div>
          </div>
          <div class="light-item">
            <div :class="{ active: lights.lowBeamLamp === 1 }" @click="lightClick('lowBeamLamp')">
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/lowBeam-${
                  lights.lowBeamLamp === 1 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>近光灯</div>
          </div>
          <div class="light-item">
            <div
              :class="{ active: lights.hightBeamLamp === 1 }"
              @click="lightClick('hightBeamLamp')"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/hightBeam-${
                  lights.hightBeamLamp === 1 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>远光灯</div>
          </div>
        </div>
      </div>
      <div class="other light-card" :class="{ disabled: standby }">
        <div class="light-card-title">其他</div>
        <div class="light-card-content">
          <div class="light-item">
            <div :class="{ active: otherOFF }" @click="lightClick('otherOFF')">
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/off-${
                  otherOFF ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>关闭</div>
          </div>
          <div class="light-item">
            <div
              :class="{ active: lights.clearanceLamp === 1 }"
              @click="lightClick('clearanceLamp')"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/clearance-${
                  lights.clearanceLamp === 1 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>示廓灯</div>
          </div>
          <div class="light-item">
            <div :class="{ active: lights.dayLamp === 1 }" @click="lightClick('dayLamp')">
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/daylight-${
                  lights.dayLamp === 1 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>昼行灯</div>
          </div>
          <div class="light-item">
            <div :class="{ active: lights.fogLamp === 1 }" @click="lightClick('fogLamp')">
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/fog-${
                  lights.fogLamp === 1 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>雾灯</div>
          </div>
        </div>
      </div>
      <div
        v-if="vType && ['A', 'B', 'C'].includes(vType.toUpperCase())"
        class="atmosphere light-card"
        :class="{ disabled: standby }"
      >
        <div class="light-card-title">氛围灯</div>
        <div class="light-card-content">
          <div class="light-item">
            <div :class="{ active: atmosphereOFF }" @click="lightClick('atmosphereOFF')">
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/off-${
                  atmosphereOFF ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>关闭</div>
          </div>
          <div class="light-item">
            <div
              :class="{ active: lights.atmosphereLamp === 2 }"
              @click="lightClick('atmosphereLamp', 2)"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/white-${
                  lights.atmosphereLamp === 2 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>白色</div>
          </div>
          <!-- X3R是青色，X3P是蓝色，枚举值一样 -->
          <div v-if="['B', 'C'].includes(vType.toUpperCase())" class="light-item">
            <div
              :class="{ active: lights.atmosphereLamp === 3 }"
              @click="lightClick('atmosphereLamp', 3)"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/cyan-${
                  lights.atmosphereLamp === 3 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>蓝绿色</div>
          </div>
          <div v-else class="light-item">
            <div
              :class="{ active: lights.atmosphereLamp === 3 }"
              @click="lightClick('atmosphereLamp', 3)"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/blue-${
                  lights.atmosphereLamp === 3 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>蓝色</div>
          </div>
          <div class="light-item">
            <div
              :class="{ active: lights.atmosphereLamp === 4 }"
              @click="lightClick('atmosphereLamp', 4)"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/red-${
                  lights.atmosphereLamp === 4 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>红色</div>
          </div>
          <div class="light-item">
            <div
              :class="{ active: lights.atmosphereLamp === 1 }"
              @click="lightClick('atmosphereLamp', 1)"
            >
              <img
                :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/colorful-${
                  lights.atmosphereLamp === 1 ? 1 : 0
                }.png`"
                alt=""
              />
            </div>
            <div>七彩色</div>
          </div>
        </div>
        <div class="mode" v-if="['B', 'C'].includes(vType.toUpperCase())">
          <div class="light-mode" v-if="[2, 3, 4].includes(lights.atmosphereLamp)">
            <span class="line"></span><span>模式选择</span><span class="line"></span>
          </div>
          <div class="light-card-content" v-if="[2, 3, 4].includes(lights.atmosphereLamp)">
            <div class="light-item">
              <div
                :class="{ active: lights.atmosphereLampMode === 0 }"
                @click="lightClick('atmosphereLampMode', 0)"
              >
                <img
                  :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/steadyOn-${
                    lights.atmosphereLampMode === 0 ? 1 : 0
                  }.png`"
                  alt=""
                />
              </div>
              <div>常亮</div>
            </div>
            <div class="light-item">
              <div
                :class="{ active: lights.atmosphereLampMode === 1 }"
                @click="lightClick('atmosphereLampMode', 1)"
              >
                <img
                  :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/flicker-${
                    lights.atmosphereLampMode === 1 ? 1 : 0
                  }.png`"
                  alt=""
                />
              </div>
              <div>闪烁</div>
            </div>
            <div class="light-item">
              <div
                :class="{ active: lights.atmosphereLampMode === 2 }"
                @click="lightClick('atmosphereLampMode', 2)"
              >
                <img
                  :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/lightsControl/breath-${
                    lights.atmosphereLampMode === 2 ? 1 : 0
                  }.png`"
                  alt=""
                />
              </div>
              <div>呼吸</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <van-skeleton title row="2"></van-skeleton>
      <van-skeleton title row="3"></van-skeleton>
      <van-skeleton title row="3"></van-skeleton>
    </template>
  </div>
</template>
<script>
import { getVinInfoForVedio, lampControl } from '@/api/apiv2';
import { Toast } from 'vant';
import Dialog from '@/components/NDialog';

export default {
  data() {
    return {
      standby: true, // 待机
      powerOn: true, // 上电
      vin: null,
      vinId: null,
      vType: null,
      lights: {},
    };
  },
  computed: {
    commonOFF() {
      return this.lights.lowBeamLamp !== 1 && this.lights.hightBeamLamp !== 1; // 近光灯和远光灯都未开
    },
    otherOFF() {
      return (
        this.lights.clearanceLamp !== 1 && this.lights.dayLamp !== 1 && this.lights.fogLamp !== 1
      ); // 示廓灯，昼行灯，雾灯
    },
    atmosphereOFF() {
      return this.lights.atmosphereLamp === 0 || this.lights.atmosphereLamp === null;
    },
  },
  mounted() {
    const { vin, vinId, vType } = this.$router.currentRoute.query;
    this.vin = vin;
    this.vinId = vinId;
    this.vType = vType;
    this.init();
  },
  methods: {
    goback() {
      window.location.href = 'neolix://interaction';
    },
    async init() {
      this.getCurrentLights();
      this.timer = setInterval(() => {
        this.getCurrentLights();
      }, 5 * 1000);
    },
    getCurrentLights() {
      getVinInfoForVedio({ vin: this.vin })
        .then((res) => {
          this.standby = res.pcuPowerState === 4;
          this.powerOn = res.pcuPowerState >= 3;
          if (!this.xhrDisabled) {
            this.lights = res;
          }
        })
        .finally(() => {
          this.$loadingCircle.end();
        });
    },
    async lightClick(type, subType) {
      if (this.standby) {
        Toast('车辆处于待机状态，不可控制灯光');
        return;
      }
      this.xhrDisabled = true; // 丢掉卡点的请求
      clearInterval(this.timer);
      clearTimeout(this.timeout);
      const prevLights = { ...this.lights }; // 备份一下之前的灯光，弹窗取消恢复灯光
      let message = '';
      let params = { vin: this.vin };
      switch (type) {
        case 'commonOFF':
          if (this.commonOFF) return;
          this.lights.lowBeamLamp = 0;
          this.lights.hightBeamLamp = 0;
          message = '确认关闭所有常用灯光吗？';
          params.lampType = [1, 2];
          params.instruction = 0;
          break;
        case 'lowBeamLamp':
          this.lights.lowBeamLamp = this.lights.lowBeamLamp === 1 ? 0 : 1;
          if (this.lights.lowBeamLamp == 0 && this.lights.hightBeamLamp == 1) {
            this.lights.hightBeamLamp = 0;
            message = '如要关闭近光灯，远光灯也将同时关闭';
            params.lampType = [1, 2];
          } else {
            message = `确认${this.lights.lowBeamLamp === 1 ? '开启' : '关闭'}近光灯吗？`;
            params.lampType = [1];
          }
          params.instruction = this.lights.lowBeamLamp;
          break;
        case 'hightBeamLamp':
          this.lights.hightBeamLamp = this.lights.hightBeamLamp === 1 ? 0 : 1;
          if (this.lights.hightBeamLamp === 1 && this.lights.lowBeamLamp !== 1) {
            this.lights.lowBeamLamp = 1;
            message = '如要打开远光灯，近光灯也将同时打开';
            params.lampType = [1, 2];
          } else {
            message = `确认${this.lights.hightBeamLamp === 1 ? '开启' : '关闭'}远光灯吗？`;
            params.lampType = [2];
          }
          params.instruction = this.lights.hightBeamLamp;
          break;
        case 'otherOFF':
          if (this.otherOFF) return;
          this.lights.clearanceLamp = 0;
          this.lights.dayLamp = 0;
          this.lights.fogLamp = 0;
          message = '确认关闭所有其他灯光吗？';
          params.lampType = [3, 4, 6];
          params.instruction = 0;
          break;
        case 'clearanceLamp':
          this.lights.clearanceLamp = this.lights.clearanceLamp === 1 ? 0 : 1;
          message = `确认${this.lights.clearanceLamp === 1 ? '开启' : '关闭'}示廓灯吗？`;
          params.lampType = [4];
          params.instruction = this.lights.clearanceLamp;
          break;
        case 'dayLamp':
          this.lights.dayLamp = this.lights.dayLamp === 1 ? 0 : 1;
          message = `确认${this.lights.dayLamp === 1 ? '开启' : '关闭'}昼行灯吗？`;
          params.lampType = [6];
          params.instruction = this.lights.dayLamp;
          break;
        case 'fogLamp':
          this.lights.fogLamp = this.lights.fogLamp === 1 ? 0 : 1;
          message = `确认${this.lights.fogLamp === 1 ? '开启' : '关闭'}雾灯吗？`;
          params.lampType = [3];
          params.instruction = this.lights.fogLamp;
          break;
        case 'atmosphereOFF':
          if (this.atmosphereOFF) return;
          this.lights.atmosphereLamp = 0;
          // message = "确认关闭所有氛围灯吗？";
          params.lampType = [5];
          params.instruction = this.lights.fogLamp;
          break;
        case 'atmosphereLampMode':
          this.lights.atmosphereLampMode = subType;
          params.lampType = [5];
          params.instruction = this.lights.atmosphereLamp;
          params.atmosphereLampModeInstruction = this.lights.atmosphereLampMode;
          break;
        case 'atmosphereLamp':
          this.lights.atmosphereLampMode =
            this.atmosphereOFF || this.lights.atmosphereLamp === 1
              ? 0
              : this.lights.atmosphereLampMode;
          this.lights.atmosphereLamp = this.lights.atmosphereLamp === subType ? 0 : subType;
          params.lampType = [5];
          params.atmosphereLampModeInstruction = this.lights.atmosphereLampMode;
          params.instruction = this.lights.atmosphereLamp;
      }
      let delay = false;

      if (message) {
        const result = await Dialog.confirm({ title: '提示', message });
        if (result === 'confirm') {
          try {
            await lampControl(params);
            delay = true;
          } catch (error) {
            this.lights = prevLights;
          }
        } else {
          this.lights = prevLights;
        }
      } else {
        try {
          await lampControl(params);
          delay = true;
        } catch (error) {
          this.lights = prevLights;
        }
      }

      this.xhrDisabled = false;
      if (delay) {
        this.timeout = setTimeout(() => {
          this.timer = setInterval(() => {
            this.getCurrentLights();
          }, 5 * 1000);
        }, 5 * 1000);
      } else {
        this.timer = setInterval(() => {
          this.getCurrentLights();
        }, 5 * 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lightControl {
  font-size: initial;
  padding-top: 40px;
  background: #f6f7f8;
  min-height: 100vh;

  .header {
    height: 40px;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;

    &-back {
      position: absolute;
      top: 4px;
      left: 10px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .light-sensitive {
      position: absolute;
      top: 2px;
      right: 20px;

      div {
        font-size: 16px;
        font-weight: 500;

        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 2px;
        }
      }
    }

    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
  }

  .warning {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    background: #fff0e9;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    span {
      font-size: 14px;
      color: #fe8000;
    }
  }
  .light-mode {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin: 16px 0;
    display: flex;
    height: 20px;
    line-height: 20px;
    justify-content: space-between;
    .line {
      display: block;
      height: 1px;
      width: 34%;
      background: rgba(241, 241, 241, 1);
      margin: 0 10px;
      margin-top: 9px;
    }
  }
  .light-card {
    margin: 12px;
    padding: 16px;
    box-sizing: border-box;
    background: white;
    border-radius: 8px;

    &-title {
      font-size: 16px;
      font-weight: 600;
    }

    &-content {
      margin: 6px;
      display: grid;

      .light-item {
        display: flex;
        align-items: center;
        flex-direction: column;

        > div {
          &:nth-child(1) {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 24px;
            background: #f6f8fd;
            margin-bottom: 8px;

            &.active {
              background: #436eff;
            }

            img {
              width: 30px;
              height: 30px;
            }
          }

          &:nth-child(2) {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    &.disabled {
      opacity: 0.7;
    }

    &.common {
      .light-card-content {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &.other {
      .light-card-content {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1fr;
        gap: 14px 0;
      }
    }

    &.atmosphere {
      .light-card-content {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1fr;
        gap: 14px 0;
      }
      .mode {
        .light-card-content {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr 0fr;
          gap: 14px 0;
        }
      }
    }
  }
}
</style>
